import type { Ref } from 'react'
import * as css from './BottomSheetDragBar.css'

export const BottomSheetDragBar = ({ ref }: { ref?: Ref<HTMLDivElement> }) => {
  return (
    <div className={css.dragBar} ref={ref}>
      <div className={css.dragBarIcon} />
    </div>
  )
}
