import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { STAGE } from '../../_app/constants/common'
import { useVConsole } from '../vconsole/useVConsole'
import { VisualizeToggleButton } from '@daangn/web-impression'
import { appBuildType } from '../../_app/utils/userAgent'
import { createRoot } from 'react-dom/client'
import SearchLabRoot from '../search-lab/components/SearchLabRoot'

interface SupportToolsProviderProps {
  children: ReactNode
}

const useImpressionVisualize = () => {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      ('development' === STAGE ||
        appBuildType(window.navigator.userAgent).isDebugApp)
    ) {
      const div = document.createElement('div')
      div.id = 'impression-visualize'
      document.body.appendChild(div)
      const visualizeRoot = createRoot(div)
      visualizeRoot.render(<VisualizeToggleButton />)
    }
  }, [])
}

const isShowSupportTools = () => {
  return (
    typeof window !== 'undefined' &&
    (STAGE === 'development' ||
      appBuildType(window.navigator.userAgent).isDebugApp)
  )
}

const SupportToolsProvider = (props: SupportToolsProviderProps) => {
  const [isShowSearchLab, setIsShowSearchLab] = useState(false)

  useVConsole()
  useImpressionVisualize()

  useEffect(() => {
    const isShow = isShowSupportTools()
    setIsShowSearchLab(isShow)
  }, [])

  return (
    <>
      {props.children}
      {isShowSearchLab && <SearchLabRoot />}
    </>
  )
}

export default SupportToolsProvider
