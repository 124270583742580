import * as Sentry from '@sentry/react'
import type { Plugin } from 'plantae'
import { getApp } from '../../bridge/utils/bridgeMaker'
import {
  satisfyAndroidVersion,
  satisfyIosVersion,
} from '../../_app/utils/version'
import type { AppType } from '../../_app/context/FoundationProvider'

const isSupportCommonHeaderPlugin = (info: {
  os: AppType['os']
  version: AppType['version']
}) => {
  return !(
    satisfyAndroidVersion(info, '<23.46.1') ||
    satisfyIosVersion(info, '<24.11.0')
  )
}

/**
 * @Deprecated
 * CommonHeaderPlugin과 연관되어 동작
 * 앱 최소 지원버전이 Android 23.46.1 이상, iOS 24.11.0 이상이 되면 플러그인 제거 검토
 *
 * 공통 헤더
 * https://www.notion.so/daangn/HTTP-9d4e958852dd45ba9bb85ae60721bff5
 */
export default function plantaeUserAgentPlugin({
  userAgent,
  os,
  version,
}: {
  userAgent?: string
  os: AppType['os']
  version: AppType['version']
}): Plugin {
  return {
    name: 'plugin-user-agent',
    hooks: {
      beforeRequest: async (req) => {
        const isIncomingSSR = !userAgent

        if (isIncomingSSR) {
          try {
            const isCommonHeaderSupported = isSupportCommonHeaderPlugin({
              os,
              version,
            })

            if (!isCommonHeaderSupported) {
              const { userAgent: bridgeUserAgent } = await getApp()
              req.headers.set('x-user-agent', bridgeUserAgent)

              return req
            }

            // commonHeadersPlugin에서 userAgent를 주입하므로 별도 추가 처리 안함
            return req
          } catch {
            const error = new Error('Failed to get userAgent from bridge')
            console.error(error)
            Sentry.captureException(error)

            return req
          }
        }

        req.headers.set('x-user-agent', userAgent)
        return req
      },
    },
  }
}
