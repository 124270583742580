import type { ReactNode } from 'react'
import { useContext } from 'react'
import { createContext, useRef } from 'react'
import type { GlobalState, GlobalStore, GlobalStoreProps } from './GlobalStore'
import { createGlobalStore } from './GlobalStore'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

const GlobalStoreContext = createContext<GlobalStore | null>(null)

interface GlobalStoreProviderProps extends GlobalStoreProps {
  children: ReactNode
}

export const GlobalStoreProvider = ({
  children,
  ...restProps
}: GlobalStoreProviderProps) => {
  const storeRef = useRef<GlobalStore>(null)

  if (!storeRef.current) {
    storeRef.current = createGlobalStore(restProps)
  }

  return (
    <GlobalStoreContext.Provider value={storeRef.current}>
      {children}
    </GlobalStoreContext.Provider>
  )
}

export function useGlobalStore<T>(selector: (state: GlobalState) => T): T {
  const store = useContext(GlobalStoreContext)

  if (!store) {
    throw new Error('Missing GlobalStoreContext.Provider in the tree')
  }

  return useStoreWithEqualityFn(store, selector, shallow)
}

export const useGlobalStoreActions = () =>
  useGlobalStore((state) => state.actions)
