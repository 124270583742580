import type { ErrorBoundaryProps } from 'react-error-boundary'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import BridgeTimeoutError from '../../../error/class/BridgeTimeoutError'
import { captureException } from '../../utils/captureException'

function ErrorBoundary({ children, ...props }: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      {...props}
      onError={(error, errorInfo) => {
        if (error instanceof BridgeTimeoutError) {
          return
        }
        captureException(error, { extra: errorInfo })
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
