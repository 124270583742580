import { useLayoutEffect, useRef, useState } from 'react'

interface BottomSheetMetrics {
  start: {
    sheetY: number // start에서 BottomSheet의 최상단 모서리의 Y값
    touchY: number // start에서 터치 포인트의 Y값
    time: number
  }
}

export const useBottomSheet = () => {
  const [isClose, setClose] = useState(false)
  const sheetRef = useRef<HTMLDivElement>(null)
  const metrics = useRef<BottomSheetMetrics>({
    start: {
      sheetY: 0,
      touchY: 0,
      time: 0,
    },
  })

  useLayoutEffect(() => {
    const currentSheetRef = sheetRef.current

    if (!currentSheetRef) {
      return
    }

    const moveSheetTranslateY = (moveY: number) => {
      requestAnimationFrame(() => {
        if (!sheetRef.current) {
          return
        }

        sheetRef.current.style.setProperty(
          'transform',
          `translate3d(0, ${moveY}px, 0)`
        )
      })
    }

    const eventTouchY = (e: MouseEvent | TouchEvent) => {
      return e instanceof MouseEvent ? e.y : e.changedTouches[0].clientY
    }

    const handleStartEvent = (e: MouseEvent | TouchEvent) => {
      const { start } = metrics.current

      start.sheetY = currentSheetRef.getBoundingClientRect().y // 시작 지점의 ViewPort Y 값
      start.touchY = eventTouchY(e)
      start.time = +new Date()
    }

    const handleMoveEvent = (e: MouseEvent | TouchEvent) => {
      e.preventDefault()

      const { start } = metrics.current
      const currentTouchY = eventTouchY(e)

      // 터치 시작점에서부터 현재 터치 포인트까지의 변화된 y값
      const touchOffset = currentTouchY - start.touchY
      let nextSheetY = start.sheetY + touchOffset

      const initViewPortY = window.innerHeight - currentSheetRef.clientHeight

      // nextSheetY 는 MIN_Y와 MAX_Y 사이의 값으로 clamp 되어야 한다
      if (nextSheetY <= initViewPortY) {
        nextSheetY = initViewPortY
      }

      const moveY = nextSheetY - initViewPortY

      moveSheetTranslateY(moveY)
    }

    const handleEndEvent = (e: MouseEvent | TouchEvent) => {
      // Snap Animation
      const currentSheetY = currentSheetRef.getBoundingClientRect().y
      const lastViewPortY = window.innerHeight - currentSheetY // 터치 종료 시의 ViewPortY
      const bottomSheetDistance = currentSheetRef.clientHeight - lastViewPortY // 바텀 시트 처음 크기에서부터의 이동 거리

      const { start } = metrics.current
      const endTime = +new Date()
      const snapDownDistance = 2
      const snapTime = endTime - start.time

      // 짧게 스냅
      if (snapTime <= 350 && snapDownDistance < bottomSheetDistance) {
        moveSheetTranslateY(currentSheetRef.clientHeight)
        setClose(true)
      } else {
        // 이동거리가 1/3보다 크면 내리기
        const downDistance = currentSheetRef.clientHeight / 3

        if (downDistance < bottomSheetDistance) {
          moveSheetTranslateY(currentSheetRef.clientHeight)
          setClose(true)
        } else {
          // 아니면 처음 크기 유지
          moveSheetTranslateY(0)
        }
      }

      // metrics 초기화.
      metrics.current = {
        start: {
          sheetY: 0,
          touchY: 0,
          time: 0,
        },
      }
    }

    currentSheetRef.addEventListener('touchstart', handleStartEvent)
    currentSheetRef.addEventListener('touchmove', handleMoveEvent)
    currentSheetRef.addEventListener('touchend', handleEndEvent)
    // pc
    // currentSheetRef.addEventListener('mousedown', handleStartEvent)
    // currentSheetRef.addEventListener('mousemove', handleMoveEvent)
    // currentSheetRef.addEventListener('mouseup', handleEndEvent)

    return () => {
      currentSheetRef.removeEventListener('touchstart', handleStartEvent)
      currentSheetRef.removeEventListener('touchmove', handleMoveEvent)
      currentSheetRef.removeEventListener('touchend', handleEndEvent)
      // pc
      // currentSheetRef.removeEventListener('mousedown', handleStartEvent)
      // currentSheetRef.removeEventListener('mousemove', handleMoveEvent)
      // currentSheetRef.removeEventListener('mouseup', handleEndEvent)
    }
  }, [])

  return {
    sheetRef,
    isClose,
  }
}
