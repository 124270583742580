import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_BFF_PUBLIC_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { SearchReferrerType } from '../../referrer/types'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { convertCollectionMetaToConnection } from '../../_app/utils/convertCollectionToConnection'
import type { ApiV1CommunitySearchPostRequest } from '../../__codegen__/__openapi__/search-bff'
import { CommunityApi } from '../../__codegen__/__openapi__/search-bff'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'
import type { WebviewConfigType } from '../../_app/types'

interface CommunitiesV4SearchRequestParamsType
  extends ApiV1CommunitySearchPostRequest {
  referrer: SearchReferrerType
}

export type ServiceSearchCommunityType = ReturnType<
  typeof getServiceSearchCommunity
>

export const getServiceSearchCommunity = ({
  user,
  app,
  webviewConfig,
  __NODE_ONLY_bypassHeaders,
}: {
  user: UserType
  app: AppType
  webviewConfig: WebviewConfigType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    headers: headers,
    timeout: webviewConfig.httpTimeoutMs ?? NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({
            userAgent: app.userAgent,
            os: app.os,
            version: app.version,
          }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: webviewConfig.retries ?? RETRY_COUNT,
    retryDelay: () => webviewConfig.retryDelay ?? 0,
    retryCondition: () => webviewConfig.retryCondition ?? true,
    shouldResetTimeout: true,
  })

  const client = new CommunityApi(
    undefined,
    SEARCH_BFF_PUBLIC_ENDPOINT,
    axiosInstance
  )

  return {
    /**
     * 동네 생활 목록 조회
     */
    getCommunities: async ({
      query,
      regionId,
      pageSize,
      sort,
      pageToken,
      lastIndex,
      referrer,
    }: CommunitiesV4SearchRequestParamsType) => {
      const [error, response] = await to(
        client.apiV1CommunitySearchPost(
          {
            apiV1CommunitySearchPostRequest: {
              regionId: regionId,
              pageSize: pageSize,
              query: query,
              pageToken: pageToken,
              lastIndex: lastIndex,
              sort: sort,
            },
          },
          {
            headers: addXSearchHeaders(referrer),
          }
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!response?.data) {
        return null
      }

      if ('error' in response.data) {
        return {
          connection: convertCollectionMetaToConnection([]),
          error: response.data.details,
        }
      }

      return {
        articleCreateBanner: response.data.articleCreateBanner,
        survey: response.data.survey,
        connection: convertCollectionMetaToConnection(response.data.documents),
      }
    },
  }
}
