import type { StateCreatorWithDevtools } from '../../../_app/zustand'

export interface SnackbarSliceType {
  snackbar: {
    isShowing: boolean
    showing: () => void
    closed: () => void
  }
}

export const snackbarSlice: StateCreatorWithDevtools<SnackbarSliceType> = (
  set
) => ({
  snackbar: {
    isShowing: false,

    showing: () =>
      set((state) => ({
        snackbar: {
          ...state.snackbar,
          isShowing: true,
        },
      })),

    closed: () =>
      set((state) => ({
        snackbar: {
          ...state.snackbar,
          isShowing: false,
        },
      })),
  },
})
