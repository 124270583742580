import type { RefObject } from 'react'
import { useLayoutEffect } from 'react'
interface useBlockTouchActionOptions {
  enableTouchAction?: boolean
}
export const useBlockTouchAction = <T extends HTMLElement>(
  ref?: RefObject<T | null>,
  options?: useBlockTouchActionOptions
) => {
  useLayoutEffect(() => {
    const scroller = ref?.current

    if (!scroller || options?.enableTouchAction) {
      return
    }

    const onTouch = (e: TouchEvent) => {
      e.stopPropagation()
    }

    scroller.addEventListener('touchstart', onTouch, { passive: true })
    scroller.addEventListener('touchmove', onTouch, { passive: true })
    scroller.addEventListener('touchend', onTouch, { passive: true })

    return () => {
      scroller.removeEventListener('touchstart', onTouch)
      scroller.removeEventListener('touchmove', onTouch)
      scroller.removeEventListener('touchend', onTouch)
    }
  }, [ref, options])
}
