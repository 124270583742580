import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { SafeAreaInsetBottom } from '../safe-area-inset/SafeAreaInset'
import * as css from './FlexibleBottomSheet.css'
import { BottomSheetDragBar as DragBar } from './BottomSheetDragBar'
import { useBottomSheet } from './useBottomSheet'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { zIndexVar } from './FlexibleBottomSheet.css'

interface BottomSheetProps {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  'aria-modal'?: boolean
  iOSSafeAreaInsetBottom?: boolean
  hideDragBar?: boolean
  bottomSheetBackgroundColor?: string
  zIndex?: number
}

const BASE_Z_INDEX = 10

const FlexibleBottomSheet = (props: BottomSheetProps) => {
  const { iOSSafeAreaInsetBottom = true, zIndex } = props
  const { sheetRef, isClose } = useBottomSheet()

  useEffect(() => {
    if (!isClose) {
      return
    }

    props.onClose()
    // eslint-disable-next-line
  }, [isClose])

  return (
    <div
      className={css.container({ isOpen: props.isOpen })}
      role="dialog"
      aria-modal={props['aria-modal'] ?? 'true'}
      aria-labelledby="bottom-sheet-title"
      style={assignInlineVars({
        [zIndexVar]: zIndex ? `${zIndex}` : `${BASE_Z_INDEX}`,
      })}
      onClick={props.onClose}
    >
      <div
        className={css.bottomToTop}
        ref={sheetRef}
        style={{
          backgroundColor: props.bottomSheetBackgroundColor,
          transform: props.isOpen
            ? 'translate3d(0, 0, 0)'
            : 'translate3d(0, 100%, 0)',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {props.hideDragBar ? null : <DragBar />}
        <>{props.children}</>
        {iOSSafeAreaInsetBottom ? <SafeAreaInsetBottom /> : null}
      </div>
    </div>
  )
}

export default FlexibleBottomSheet
