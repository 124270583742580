import { useRef, useState } from 'react'
import useTouchDraggable from '../hooks/useTouchDraggable'
import PageSearchLab from '../../_pages/PageSearchLab'

const SearchLabRoot = () => {
  const [isOpen, setIsOpen] = useState(false)

  const buttonRef = useRef(null)
  const { position, onTouchStart, onTouchMove } = useTouchDraggable({
    elRef: buttonRef,
    initialPosition: {
      top: typeof window !== 'undefined' ? window.innerHeight - 100 : 0,
      left: typeof window !== 'undefined' ? window.innerWidth - 200 : 0,
    },
  })

  return (
    <>
      <div
        ref={buttonRef}
        onClick={() => {
          setIsOpen(true)
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        style={{
          position: 'fixed',
          top: `${position.top}px`,
          left: `${position.left}px`,
          backgroundColor: 'gray',
          width: '86px',
          borderRadius: '8px',
          padding: '4px',
          fontSize: '13px',
          color: 'white',
          textAlign: 'center',
          zIndex: '9999',
        }}
      >
        검색 실험실
      </div>

      <PageSearchLab isShow={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}

export default SearchLabRoot
