import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  IS_ENV_SSR,
  RETRY_COUNT,
  SEARCH_BFF_PUBLIC_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'

import plantaeSearchUserIdPlugin from '../../plantae/plugins/plantaeSearchUserIdPlugin'
import { InitializeApi } from '../../__codegen__/__openapi__/search-bff'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'

export type ServiceV4InitializeType = ReturnType<typeof ServiceV4Initialize>

export function getServiceV4Initialize({
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) {
  return ServiceV4Initialize({
    baseUrl: SEARCH_BFF_PUBLIC_ENDPOINT,
    app,
    user,
    __NODE_ONLY_bypassHeaders,
  })
}

const ServiceV4Initialize = ({
  baseUrl,
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  baseUrl: string
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({
            userAgent: app.userAgent,
            os: app.os,
            version: app.version,
          }),
          plantaeSearchUserIdPlugin({ userId: user.id }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
  })

  const client = new InitializeApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 검색 초기 데이터 가져오기(실험정보, impression 정보)
     */
    async getAppInitialize() {
      const [error, response] = await to(client.apiV1InitializeGet())

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('status' in response.data) {
        return null
      }

      return response.data
    },
  }
}
