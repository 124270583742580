import { debounce, throttle } from 'lodash-es'

type Listener = () => void

let installed = false

export const isIOSWebView =
  typeof window !== 'undefined' && window.webkit !== undefined

export type Detector = {
  uninstall: () => void
  addListener: (listener: Listener) => () => void
}

export function installDetector(): Detector {
  if (installed) {
    throw new Error('Scroll to top detection is already installed')
  }

  installed = true

  let touched = false
  let prevScrollY = 1 // scroll이 항상 1 내려가있어야, iOS scroll to top이 동작하므로 초기값은 1

  const listeners: Set<Listener> = new Set()

  function addListener(listener: Listener) {
    listeners.add(listener)

    function disposeListener() {
      listeners.delete(listener)
    }

    return disposeListener
  }

  function resetTouchState() {
    touched = false
    window.document.body.style.overflow = ''
  }

  const throttledTouchMove = debounce(function () {
    if (!isIOSWebView || !touched) {
      return
    }

    resetTouchState()
  }, 100)

  const throttleScroll = throttle(function () {
    if (touched) {
      /**
       * HACK: https://stackoverflow.com/questions/7691551/touchend-event-in-ios-webkit-not-firing
       *
       * 브라우저가 사용자의 의도를 감지하고 touchstart 이벤트가 발생한 후 사용자가 손가락을 움직이면, 브라우저는 내부적으로 제스처를 분석해 "이 터치가 스크롤을 의도한 것인지" 판단함
       * 만약 브라우저가 "스크롤 동작"이라고 판단하면, touchmove, touchend, touchcancel 같은 이벤트를 웹 컨텐츠에서 받을 수 없게 하고 스크롤 동작으로 전환해버림.
       * 따라서, 이 경우엔 그냥 touched 상태를 true로 만듬
       *  */
      resetTouchState()
    }

    if (window.scrollY !== 0) {
      // touched 리셋 이후에도 scroll 이벤트가 연속적으로 인입되는 경우가 있어서, 직전 scroll 값을 저장해둠
      prevScrollY = window.scrollY
      return
    }

    // 연속적으로 인입되는 scroll 이벤트를 방지하기 위해, 직전 scroll 값이 1이 아닌 경우 1로 스크롤을 이동
    if (prevScrollY !== 1) {
      window.scroll({ top: 1 })
      return
    }

    // --- 여기서부턴 window.scrollY가 0이면서 직전 값이 1인 경우만 ---
    const isKeyboardVisible = window.visualViewport
      ? window.innerHeight - window.visualViewport.height > 0
      : false

    if (isKeyboardVisible) {
      window.scroll({ top: 1 })
      return
    }

    listeners.forEach((listener) => listener())

    // install시점에서 window.outerHeight가 0으로 잡히는 경우가 있어서 onScroll시 마다 갱신.
    window.document.body.style.height = window.outerHeight + 1 + 'px'
    window.scroll({ top: 1 })
  }, 100)

  function onScroll() {
    throttleScroll()
  }

  function onTouchStart() {
    if (!isIOSWebView) {
      return
    }

    touched = true
    window.document.body.style.overflow = 'hidden'
  }

  function onTouchEnd() {
    if (!isIOSWebView || !touched) {
      return
    }

    resetTouchState()
  }

  function onTouchMove() {
    if (!isIOSWebView || !touched) {
      return
    }

    throttledTouchMove()
  }

  function onResize() {
    const outerHeight = window.outerHeight

    if (outerHeight <= 0) {
      return
    }

    window.document.body.style.height = outerHeight + 1 + 'px'
  }

  function install() {
    window.document.body.style.height = window.outerHeight + 1 + 'px'
    window.scroll({ top: 1 })

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onScroll)
    window.document.body.addEventListener('touchstart', onTouchStart)
    window.document.body.addEventListener('touchmove', onTouchMove, {
      passive: true,
    })
    window.document.body.addEventListener('touchend', onTouchEnd)
    window.document.body.addEventListener('touchcancel', onTouchEnd)
  }

  function uninstall() {
    window.document.body.style.height = ''
    window.scroll({ top: 0 })

    window.removeEventListener('resize', onResize)
    window.removeEventListener('scroll', onScroll)
    window.document.body.removeEventListener('touchstart', onTouchStart)
    window.document.body.removeEventListener('touchmove', onTouchMove)
    window.document.body.removeEventListener('touchend', onTouchEnd)
    window.document.body.removeEventListener('touchcancel', onTouchEnd)

    listeners.clear()

    installed = false
  }

  if (isIOSWebView) {
    if (document.readyState === 'complete') {
      install()
    } else {
      window.addEventListener('load', install)
    }
  }

  return {
    uninstall,
    addListener,
  }
}
