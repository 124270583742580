import {
  ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum,
  type ShortformSearchSuccessResponseSearchComponentsInner,
} from '../../__codegen__/__openapi__/search-bff'

interface DocumentCommonType {
  docToken?: string
}

export const convertCollectionToConnection = <T extends DocumentCommonType>(
  collection?: Array<T>
) => {
  return {
    edges:
      collection
        ?.filter((doc) => doc)
        .map((doc) => ({
          node: doc,
          cursor: doc.docToken ?? '',
        })) ?? [],
  }
}

interface DocumentCommonMetaType {
  meta?: {
    index?: number
    cursor?: string
  }
}

export const convertCollectionMetaToConnection = <
  T extends DocumentCommonMetaType,
>(
  collection?: Array<T>
) => {
  return {
    edges:
      collection
        ?.filter((doc) => doc)
        .map((doc) => ({
          node: doc,
          cursor: doc.meta?.cursor ?? '',
        })) ?? [],
  }
}

export const convertShortformSearchItemToConnection = (
  searchItems?: Array<ShortformSearchSuccessResponseSearchComponentsInner>
) => {
  return {
    edges:
      searchItems
        ?.filter((doc) => doc)
        .map((doc) => {
          return {
            node: {
              componentType:
                doc.searchItem?.searchItemKey ===
                ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum.SHORTFORM
                  ? ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum.SHORTFORM
                  : ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum.UNSPECIFIED,
              component: {
                shortform: doc.searchItem?.shortform,
              },
              meta: {
                cursor: doc.searchItem?.nextDocToken ?? '',
                id: doc.searchItem?.shortform?.id ?? '',
                index: doc.index,
              },
            },
            cursor: doc.searchItem?.nextDocToken ?? '',
          }
        }) ?? [],
  }
}
