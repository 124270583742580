import { createContext as createGraphQLContext } from '../_app/graphql/context'
import { useFoundation } from '../_app/context/FoundationProvider'
import { useNodeOnlySSRBypassHeaderContext } from '../_app/context/NodeOnlySSRBypassHeaderProvider'
import { useExperimentSegment } from '../experiment/hooks/useExperimentSegment'
import { createContext, useContext, useMemo } from 'react'
import { useWebviewConfigContext } from '../_app/context/AppInitializeProvider'

export const QueryEnvironmentContext = createContext<ReturnType<
  typeof createGraphQLContext
> | null>(null)

export const QueryEnvironmentProvider = (props: React.PropsWithChildren) => {
  const { app, user, region } = useFoundation()
  const nodeOnlySSRBypassHeader = useNodeOnlySSRBypassHeaderContext()
  const { experimentHeaderSegmentReferrer } = useExperimentSegment()
  const webviewConfig = useWebviewConfigContext()

  const memoriesEnvironment = useMemo(
    () =>
      createGraphQLContext({
        app: app,
        user,
        regionId: region?.id,
        experiment: experimentHeaderSegmentReferrer,
        webviewConfig: webviewConfig,
        __NODE_ONLY_bypassHeaders: nodeOnlySSRBypassHeader,
      }),
    [
      app,
      region?.id,
      user,
      experimentHeaderSegmentReferrer,
      webviewConfig,
      nodeOnlySSRBypassHeader,
    ]
  )

  return (
    <QueryEnvironmentContext.Provider value={memoriesEnvironment}>
      {props.children}
    </QueryEnvironmentContext.Provider>
  )
}

export const useQueryEnvironment = () => {
  const context = useContext(QueryEnvironmentContext)
  if (!context) {
    throw new Error(
      `useQueryEnvironment cannot be called outside the QueryEnvironmentProvider`
    )
  }
  return context
}
