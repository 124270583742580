import type { AxiosResponse } from 'axios'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_BFF_PUBLIC_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import type { ApiV1CategoriesFleaMarketGet200Response } from '../../__codegen__/__openapi__/search-bff'
import {
  CategoriesApi,
  Configuration,
} from '../../__codegen__/__openapi__/search-bff'

import type { SearchExperimentReferrerType } from '../../referrer/types'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'
import type { WebviewConfigType } from '../../_app/types'

export const getServiceCategory = ({
  user,
  app,
  webviewConfig,
  __NODE_ONLY_bypassHeaders,
}: {
  user: UserType
  app: AppType
  webviewConfig: WebviewConfigType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  return new ServiceCategory({
    user,
    app,
    webviewConfig,
    __NODE_ONLY_bypassHeaders,
  })
}

export class ServiceCategory {
  private client
  private fleaMarketCategoryListCache: ApiV1CategoriesFleaMarketGet200Response | null =
    null
  private fleaMarketCategoryListPromise: Promise<
    AxiosResponse<ApiV1CategoriesFleaMarketGet200Response | null>
  > | null = null

  constructor({
    user,
    app,
    webviewConfig,
    __NODE_ONLY_bypassHeaders,
  }: {
    user: UserType
    app: AppType
    webviewConfig: WebviewConfigType
    __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
  }) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    }
    const axiosInstance = axios.create({
      headers,
      timeout: webviewConfig.httpTimeoutMs ?? NETWORK_TIMEOUT,
    })
    daangnAxiosInterceptors({
      client: axiosInstance,
      plugins: IS_ENV_SSR
        ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
        : [
            plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
            plantaeRequestIdPlugin(),
            plantaeKarrotSessionIdPlugin({ app }),
            plantaeUserAgentPlugin({
              userAgent: app.userAgent,
              os: app.os,
              version: app.version,
            }),
            plantaeCommonHeadersPlugin(),
            plantaeSearchOriginPlugin(),
            plantaeSearchWebVersionPlugin(),
          ],
    })

    axiosRetry(axiosInstance, {
      retries: webviewConfig.retries ?? RETRY_COUNT,
      retryDelay: () => webviewConfig.retryDelay ?? 0,
      retryCondition: () => webviewConfig.retryCondition ?? true,
      shouldResetTimeout: true,
    })
    this.client = new CategoriesApi(
      new Configuration({
        apiKey: user.authToken,
      }),
      SEARCH_BFF_PUBLIC_ENDPOINT,
      axiosInstance
    )
  }
  /**
   * 중고거래 카테고리 리스트 조회
   */
  async getFleaMarketCategoryList({
    referrer,
  }: {
    referrer: {
      experiment: SearchExperimentReferrerType
    }
  }) {
    if (this.fleaMarketCategoryListCache) {
      // 캐싱된 데이터가 있으면 캐싱된 데이터를 반환
      return this.fleaMarketCategoryListCache
    }

    if (!this.fleaMarketCategoryListPromise) {
      this.fleaMarketCategoryListPromise =
        this.client.apiV1CategoriesFleaMarketGet(
          {},
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
    }

    const [error, resp] = await to(this.fleaMarketCategoryListPromise)

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data || !resp?.data?.categories) {
      return null
    }

    this.fleaMarketCategoryListCache = resp.data
    return this.fleaMarketCategoryListCache
  }
}
