import { SnackbarProvider } from '@daangn/sprout-components-snackbar'
import type { ReactNode } from 'react'
import { Suspense, memo, useCallback } from 'react'
import { SearchFunnelIdProvider } from './referrer/context/SearchFunnelIdProvider'
import { Stack } from './stackflow/stackflow'
import { SimpleError } from './_app/components/error/SimpleError'
import { ImpressionLoggerRoot } from './_app/components/impression/Impression'
import EntryPointSkeleton from './_app/components/skeleton/EntryPointSkeleton'
import type { InitializeType } from './_app/context/AppInitializeProvider'
import { AppInitializeProvider } from './_app/context/AppInitializeProvider'
import type { FoundationType } from './_app/context/FoundationProvider'
import { FoundationProvider } from './_app/context/FoundationProvider'
import GlobalMutableStateProvider from './_app/context/GlobalMutableStateProvider'
import { SearchGlobalEventBusProvider } from './_app/context/SearchGlobalEventBus'
import SupportToolsProvider from './__internal__/context/SupportToolsProvider'
import type { DehydratedState, QueryClient } from '@tanstack/react-query'
import { QueryClientProvider, HydrationBoundary } from '@tanstack/react-query'
import { IS_ENV_SSR } from './_app/constants/common'
import { NodeOnlySSRBypassHeaderProvider } from './_app/context/NodeOnlySSRBypassHeaderProvider'
import { NodeOnlyRenderPlatformProvider } from './_app/context/NodeOnlyRenderPlatformProvider'
import type { RenderPlatformType } from './_app/utils/getRenderEnvironment'
import ErrorBoundary from './_app/components/error/ErrorBoundary'
import { QueryEnvironmentProvider } from './react-query/QueryEnvironmentProvider'
import type { WebviewConfigType } from './_app/types'

interface AppProps {
  url: string
  theme: 'cupertino' | 'android'
  foundation?: FoundationType
  initialize?: InitializeType
  webviewConfig?: WebviewConfigType
  queryClient: QueryClient
  dehydrate?: DehydratedState
  __NODE_ONLY_bypassHeader?: ({ [x: string]: string | undefined } | null)[]
  __NODE_ONLY_platform?: RenderPlatformType
}

/**
 * SnackbarProvider의 ssr hydration 이슈로 인해 Suspense로 감싸 임시 해결
 * csr 상황인 경우, Suspense를 사용하면, search/discover 접근시 깜빡이는 이슈 존재
 * 참고: https://daangn.slack.com/archives/CGDR2PPM2/p1718077603648649
 */
const SuspenseForSnackbarBug = ({
  useSuspense,
  children,
}: {
  useSuspense: boolean
  children: ReactNode
}) => {
  if (useSuspense) {
    return <Suspense fallback={null}>{children}</Suspense>
  }

  return <>{children}</>
}
const App = ({
  url,
  theme,
  foundation,
  initialize,
  webviewConfig,
  queryClient,
  dehydrate,
  __NODE_ONLY_bypassHeader,
  __NODE_ONLY_platform,
}: AppProps) => {
  const handleErrorReload = useCallback(() => {
    window.location.reload() // chunk error 시 reload 유도를 위함
  }, [])

  return (
    <ErrorBoundary fallback={<SimpleError retry={handleErrorReload} />}>
      <NodeOnlyRenderPlatformProvider renderPlatform={__NODE_ONLY_platform}>
        <NodeOnlySSRBypassHeaderProvider headers={__NODE_ONLY_bypassHeader}>
          <EntryPointSkeleton url={url}>
            <FoundationProvider foundation={foundation}>
              <AppInitializeProvider
                initialize={initialize}
                webviewConfig={webviewConfig}
              >
                <SupportToolsProvider>
                  <GlobalMutableStateProvider>
                    <SearchFunnelIdProvider>
                      <ImpressionLoggerRoot>
                        <QueryEnvironmentProvider>
                          <QueryClientProvider client={queryClient}>
                            <HydrationBoundary state={dehydrate}>
                              <SearchGlobalEventBusProvider>
                                <SnackbarProvider>
                                  <SuspenseForSnackbarBug
                                    useSuspense={!!dehydrate || IS_ENV_SSR}
                                  >
                                    <Stack
                                      initialContext={{
                                        req: { path: url },
                                        theme,
                                        __NODE_ONLY_platform,
                                      }}
                                    />
                                  </SuspenseForSnackbarBug>
                                </SnackbarProvider>
                              </SearchGlobalEventBusProvider>
                            </HydrationBoundary>
                          </QueryClientProvider>
                        </QueryEnvironmentProvider>
                      </ImpressionLoggerRoot>
                    </SearchFunnelIdProvider>
                  </GlobalMutableStateProvider>
                </SupportToolsProvider>
              </AppInitializeProvider>
            </FoundationProvider>
          </EntryPointSkeleton>
        </NodeOnlySSRBypassHeaderProvider>
      </NodeOnlyRenderPlatformProvider>
    </ErrorBoundary>
  )
}

export default memo(App)
