import FlexibleBottomSheet from '../../_app/components/bottom-sheet/FlexibleBottomSheet'
import { ROUTES } from '../../stackflow/stackflow'
import { useDomSticker } from '../search-lab/hooks/useDomSticker'
import { FUNNEL_FROM } from '../../referrer/constants'
import * as css from './PageSearchLab.css'
import { useFoundation } from '../../_app/context/FoundationProvider'
import { useExperimentContext } from '../../_app/context/AppInitializeProvider'
import { useRef } from 'react'
import { useBlockTouchAction } from '../../_app/hooks/useBlockTouchAction'

interface PageSearchLabProps {
  isShow: boolean
  onClose: () => void
}

const PageSearchLab = ({ isShow, onClose }: PageSearchLabProps) => {
  const { isAttached, isDetached } = useDomSticker({
    isVisible: isShow,
  })

  if (isDetached) {
    return null
  }

  return (
    <FlexibleBottomSheet isOpen={isAttached} onClose={onClose} zIndex={999999}>
      <div className={css.scrollContainer}>
        <PageSearchLabContents />
      </div>
    </FlexibleBottomSheet>
  )
}

const PageSearchLabContents = () => {
  const foundation = useFoundation()
  const { experimentSegments } = useExperimentContext()

  const movePage = (
    url: (typeof ROUTES)[keyof typeof ROUTES],
    params?: Record<string, string>
  ) => {
    window.location.href = url
      ? `${url}${params ? `?${new URLSearchParams(params).toString()}` : ''}`
      : ''
  }

  const scrollerRef = useRef<HTMLDivElement>(null)
  useBlockTouchAction<HTMLDivElement>(scrollerRef)

  return (
    <div className={css.contents} ref={scrollerRef}>
      <section className={css.section}>
        <div className={css.header}>
          <h2 className={css.title}>유저</h2>
        </div>
        <label>
          <span
            className={css.description}
          >{`${foundation.user.nickname} / ${foundation.user.id}`}</span>
        </label>
      </section>
      <section className={css.section}>
        <div className={css.header}>
          <h2 className={css.title}>지역</h2>
        </div>
        <label>
          <span
            className={css.description}
          >{`${foundation.region.name} / ${foundation.region.id}`}</span>
        </label>
      </section>
      {experimentSegments.length > 0 && (
        <section className={css.section}>
          <div className={css.header}>
            <h2 className={css.title}>검색 실험</h2>
          </div>
          <div className={css.description}>
            <ul className={css.list}>
              {experimentSegments.map((experiment) => {
                return (
                  <li key={experiment.segmentKey}>
                    {experiment.segmentKey} [{experiment.segmentName}]
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      )}
      <div className={css.divider} />
      <section className={css.actions}>
        <button
          className={css.button}
          onClick={() => movePage(ROUTES.GenAiCover, {})}
        >
          <BuddyIcon />
          <span className={css.buttonText}>Search GPT</span>
        </button>
        <button
          className={css.button}
          onClick={() =>
            movePage(ROUTES.HomeSearch, {
              funnelFrom: FUNNEL_FROM.HOME.ID,
            })
          }
        >
          통합 검색 페이지
        </button>
        <button
          className={css.button}
          onClick={() =>
            movePage(ROUTES.HomeSearch, {
              funnelFrom: FUNNEL_FROM.HOME.ID,
              categoryId: '1',
            })
          }
        >
          통합 검색 페이지 (with 카테고리)
        </button>
        {/* <button
          className={css.button}
          onClick={() =>
            movePage(ROUTES.HomeSearch, {
              funnelFrom: FUNNEL_FROM.HOME.ID,
              categoryId: '1',
            })
          }
        >
          Impression 시각화 활성화
        </button> */}
      </section>
    </div>
  )
}

const BuddyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="buddy star">
      <path
        id="Star 1"
        d="M6.64974 2.52734C7.04999 1.04081 7.25011 0.297544 7.56338 0.116877C7.8336 -0.0389591 8.1664 -0.0389591 8.43662 0.116877C8.74989 0.297544 8.95001 1.04081 9.35026 2.52734L10.0154 4.99786C10.0917 5.2811 10.1298 5.42273 10.2051 5.53851C10.2717 5.64095 10.359 5.7283 10.4615 5.7949C10.5773 5.87016 10.7189 5.9083 11.0021 5.98456L13.4727 6.64974C14.9592 7.04999 15.7025 7.25011 15.8831 7.56338C16.039 7.8336 16.039 8.1664 15.8831 8.43662C15.7025 8.74989 14.9592 8.95001 13.4727 9.35026L11.0021 10.0154C10.7189 10.0917 10.5773 10.1298 10.4615 10.2051C10.359 10.2717 10.2717 10.359 10.2051 10.4615C10.1298 10.5773 10.0917 10.7189 10.0154 11.0021L9.35026 13.4727C8.95001 14.9592 8.74989 15.7025 8.43662 15.8831C8.1664 16.039 7.8336 16.039 7.56338 15.8831C7.25011 15.7025 7.04999 14.9592 6.64974 13.4727L5.98456 11.0021C5.9083 10.7189 5.87016 10.5773 5.7949 10.4615C5.7283 10.359 5.64095 10.2717 5.53851 10.2051C5.42273 10.1298 5.2811 10.0917 4.99786 10.0154L2.52734 9.35026C1.04081 8.95001 0.297544 8.74989 0.116877 8.43662C-0.0389591 8.1664 -0.0389591 7.8336 0.116877 7.56338C0.297544 7.25011 1.04081 7.04999 2.52734 6.64974L4.99786 5.98456C5.2811 5.9083 5.42273 5.87016 5.53851 5.7949C5.64095 5.7283 5.7283 5.64095 5.7949 5.53851C5.87016 5.42273 5.9083 5.2811 5.98456 4.99786L6.64974 2.52734Z"
        fill="url(#paint0_linear_1904_189446)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1904_189446"
        x1="3.45102"
        y1="2.30082"
        x2="12.9674"
        y2="14.0132"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.455" stopColor="#8361E8" />
        <stop offset="1" stopColor="#57C7FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default PageSearchLab
