import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_BFF_PUBLIC_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeKarrotUserIdPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'
import { FleamarketApi } from '../../__codegen__/__openapi__/search-bff'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'
import type { WebviewConfigType } from '../../_app/types'

export type ServiceKarrotMarketType = ReturnType<typeof getServiceKarrotMarket>

export const getServiceKarrotMarket = ({
  app,
  user,
  webviewConfig,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  webviewConfig: WebviewConfigType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: webviewConfig.httpTimeoutMs ?? NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({
            userAgent: app.userAgent,
            os: app.os,
            version: app.version,
          }),
          plantaeKarrotUserIdPlugin({ app, userId: user.id }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: webviewConfig.retries ?? RETRY_COUNT,
    retryDelay: () => webviewConfig.retryDelay ?? 0,
    retryCondition: () => webviewConfig.retryCondition ?? true,
    shouldResetTimeout: true,
  })

  const client = new FleamarketApi(
    undefined,
    SEARCH_BFF_PUBLIC_ENDPOINT,
    axiosInstance
  )

  return {
    /**
     * 중고거래 필터, 지역 범위
     * @param regionId
     */
    async getRegionRanges({ regionId }: { regionId: number }) {
      const [error, resp] = await to(
        client.apiV1FleamarketRegionRegionRangesGet({
          regionId: regionId.toString(),
          rangeType: 'view',
          polygonFormat: 'geojson',
        })
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      if ('status' in resp.data) {
        return null
      }

      return resp.data.regionRanges
    },
  }
}
