import { createContext, useContext, useEffect, useRef } from 'react'
import type { StackflowReactPlugin } from '@stackflow/react'
import type { Detector } from './iOSScrollToTopDetector'
import { installDetector } from './iOSScrollToTopDetector'
import { isIOSWebView } from './iOSScrollToTopDetector'

const detector: Detector | null = isIOSWebView ? installDetector() : null

const DetectorContext = createContext<Detector>(null as any)

export const iOSScrollToTopPlugin: () => StackflowReactPlugin = () => () => ({
  key: 'stackflow-plugin-scroll-to-top',
  wrapStack({ stack }) {
    if (detector) {
      return (
        <DetectorContext.Provider value={detector}>
          {stack.render()}
        </DetectorContext.Provider>
      )
    } else {
      return <>{stack.render()}</>
    }
  },
})

export function useIOSScrollToTopEffect(
  cb: () => void,
  deps?: any[],
  options?: {
    isActive: boolean
  }
) {
  const detector = useContext(DetectorContext)
  const disposeRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (!isIOSWebView) {
      return
    }

    if (!options?.isActive) {
      disposeRef.current?.()
    } else {
      disposeRef.current = detector?.addListener(cb)
    }

    return () => {
      disposeRef.current?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.isActive, detector, ...(deps ?? [])])
}
