import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type DomStickerStatusType =
  | 'BEFORE_ATTACH'
  | 'ATTACHED'
  | 'AFTER_ATTACH'
  | 'BEFORE_DETACH'
  | 'DETACHED'
  | 'AFTER_DETACHED'

interface useDomStickerProps {
  isVisible: boolean
  duration?: number
}

const MIN_DURATION = 16

export const useDomSticker = ({
  isVisible,
  duration = 200,
}: useDomStickerProps) => {
  const initialStatus = useMemo(
    () => (isVisible ? 'AFTER_ATTACH' : 'AFTER_DETACHED'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [status, setStatus] = useState<DomStickerStatusType>(initialStatus)
  const isMount = useRef(false)

  useEffect(() => {
    if (!isMount.current) {
      return
    }

    if (isVisible) {
      setStatus('BEFORE_ATTACH')
      return
    }

    setStatus('BEFORE_DETACH')
  }, [isVisible, initialStatus])

  useEffect(() => {
    isMount.current = true // 프로덕션에서 불필요한 렌더링 방지
  }, [])

  const timer = useRef<NodeJS.Timeout | null>(null)
  const animationTimer = useRef<NodeJS.Timeout | null>(null)

  const setTimerBy = useCallback(
    (status: DomStickerStatusType) => {
      if (status === 'BEFORE_ATTACH') {
        animationTimer.current = setTimeout(() => setStatus('ATTACHED'), 0)
        return
      }

      if (status === 'BEFORE_DETACH') {
        animationTimer.current = setTimeout(() => setStatus('DETACHED'), 0)
        return
      }

      if (status === 'ATTACHED') {
        timer.current = setTimeout(
          () => setStatus('AFTER_ATTACH'),
          Math.max(duration, MIN_DURATION)
        )
        return
      }

      if (status === 'DETACHED') {
        timer.current = setTimeout(
          () => setStatus('AFTER_DETACHED'),
          Math.max(duration, MIN_DURATION)
        )
        return
      }
    },
    [duration]
  )

  const clearTimer = useCallback(() => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
      timer.current = null
    }
    if (animationTimer.current !== null) {
      clearTimeout(animationTimer.current)
      animationTimer.current = null
    }
  }, [])

  useEffect(() => {
    setTimerBy(status)

    return () => {
      clearTimer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return {
    isAttached: status === 'AFTER_ATTACH',
    isChanging: status !== 'AFTER_ATTACH' && status !== 'AFTER_DETACHED',
    isDetached: status === 'AFTER_DETACHED',
  }
}
