import type { TouchEvent } from 'react'
import { useCallback, useRef, useState, useEffect } from 'react'

const useTouchDraggable = ({
  elRef,
  initialPosition,
}: {
  elRef: { current: HTMLElement | null }
  initialPosition?: { top: number; left: number }
}) => {
  const startPositionRef = useRef({
    top: initialPosition?.top ?? 0,
    left: initialPosition?.left ?? 0,
  })
  const [position, setPosition] = useState({
    top: startPositionRef.current.top,
    left: startPositionRef.current.left,
  })

  // 애니메이션 프레임 요청 ID를 저장
  const rafRef = useRef<number | null>(null)

  const onTouchStart = useCallback((e: TouchEvent<HTMLDivElement>) => {
    // passive 이벤트가 아닌 경우에만 preventDefault 호출
    if (!e.defaultPrevented) {
      e.preventDefault()
    }

    startPositionRef.current = {
      top: e.changedTouches[0].pageY,
      left: e.changedTouches[0].pageX,
    }
  }, [])

  const onTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      // passive 이벤트가 아닌 경우에만 preventDefault 호출
      if (!e.defaultPrevented) {
        e.preventDefault()
      }

      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }

      rafRef.current = requestAnimationFrame(() => {
        if (!elRef.current) return

        const delta = {
          top: e.changedTouches[0].pageY - startPositionRef.current.top,
          left: e.changedTouches[0].pageX - startPositionRef.current.left,
        }

        setPosition({
          top: elRef.current.offsetTop + delta.top,
          left: elRef.current.offsetLeft + delta.left,
        })

        startPositionRef.current = {
          top: e.changedTouches[0].pageY,
          left: e.changedTouches[0].pageX,
        }
      })
    },
    [elRef]
  )

  // cleanup
  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }
    }
  }, [])

  return { position, onTouchStart, onTouchMove }
}

export default useTouchDraggable
