import type { FunnelFromIdTypes } from '../constants'
import { SCREEN_DEPTH_NAMES } from '../constants'
import { FUNNEL_FROM, QUERY_FROM } from '../constants'
import { findConstantObjectById } from '../../_app/utils/helpers'
import type { InputViewStateType } from '../../input-nav-bar/context/SearchInputActionProvider'
import type { HomeTabsType } from '../../_app/types/homeTabs'

export const findQueryFromValueById = (queryFromId?: string) => {
  if (!queryFromId) {
    return QUERY_FROM.UNKNOWN.VALUE
  }

  const queryFromConstant = findConstantObjectById(QUERY_FROM, queryFromId)

  return queryFromConstant ? queryFromConstant.VALUE : QUERY_FROM.UNKNOWN.VALUE
}

export const findFunnelFromValueById = (funnelFromId?: string) => {
  if (!funnelFromId) {
    return FUNNEL_FROM.UNKNOWN.VALUE
  }

  const funnelFromConstant = findConstantObjectById(FUNNEL_FROM, funnelFromId)

  return funnelFromConstant
    ? funnelFromConstant.VALUE
    : FUNNEL_FROM.UNKNOWN.VALUE
}

export function injectFunnelFromSchemeUrl(
  targetUri: string,
  funnelFromId: FunnelFromIdTypes
) {
  if (!targetUri) {
    return targetUri
  }

  const originalTargetUrl = new URL(targetUri)

  // 로컬 웹뷰
  const pathParams = originalTargetUrl.searchParams.get('path')

  if (pathParams) {
    const pathUrl = new URL(pathParams, 'file://') // file:// dummy
    pathUrl.searchParams.set(
      'funnelFrom',
      findFunnelFromValueById(funnelFromId)
    )
    originalTargetUrl.searchParams.set(
      'path',
      `${pathUrl.pathname}${pathUrl.search}`
    )

    return originalTargetUrl.toString()
  }

  // 리모트 웹뷰
  const remoteParams = originalTargetUrl.searchParams.get('remote')

  if (remoteParams) {
    const params = new URL(remoteParams)
    params.searchParams.set('funnelFrom', findFunnelFromValueById(funnelFromId))
    originalTargetUrl.searchParams.set('remote', params.toString())

    return originalTargetUrl.toString()
  }

  // 그 외(앱 스킴)는 아무런 처리 하지 않음
  return targetUri
}

/**
 * Remote 웹뷰 Only
 * @param targetUri
 * @param additionalQueryParams
 */
export function injectParamsFromSchemeUrl(
  targetUri: string,
  additionalQueryParams: Record<string, string>
) {
  if (!targetUri) {
    return targetUri
  }

  const originalTargetUrl = new URL(targetUri)
  const remoteParams = originalTargetUrl.searchParams.get('remote')

  if (remoteParams) {
    const params = new URL(remoteParams)

    for (const key in additionalQueryParams) {
      params.searchParams.set(key, additionalQueryParams[key])
    }

    originalTargetUrl.searchParams.set('remote', params.toString())

    return originalTargetUrl.toString()
  }

  return targetUri
}

/**
 * funnelFromId 값 보정
 * @param funnelFromId
 */
export function correctFunnelFromId(funnelFromId?: FunnelFromIdTypes | null) {
  if (!funnelFromId) {
    return FUNNEL_FROM.UNKNOWN.ID
  }

  if (funnelFromId === FUNNEL_FROM.STORY.ID) {
    return FUNNEL_FROM.COMMUNITY.ID
  }

  return funnelFromId
}

export function currentScreenDepthName({
  viewState,
  isMapVisible,
  tab,
}: {
  viewState: InputViewStateType
  isMapVisible: boolean
  tab: HomeTabsType
}) {
  if (viewState.isAutoCompleteVisible) {
    return SCREEN_DEPTH_NAMES.AUTOCOMPLETE
  }

  if (viewState.isDiscoverVisible) {
    return SCREEN_DEPTH_NAMES.DISCOVERY
  }

  if (isMapVisible && tab === 'BIZ_ACCOUNT') {
    return SCREEN_DEPTH_NAMES.MAP
  }

  return SCREEN_DEPTH_NAMES.RESULT
}
