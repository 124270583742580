import { SENTRY_DSN, STAGE, VERSION } from '../constants/common'
import * as Sentry from '@sentry/react'
import * as SentryBrowser from '@sentry/browser'

/**
 * 웹뷰에서 컨트롤하기 어려운 네이티브와 관련된 에러들의 SampleRate를 별도로 관리
 */
const MORE_SAMPLE_RATE_NATIVE_ERRORS = [
  'Error: Java bridge method invocation error',
  'Java object is gone',
  'Java bridge method invocation error',
]

/**
 * (임시조치) 이슈 해결 필요
 * 임시로 추가 샘플링이 들어가는 에러들
 */
const MORE_SAMPLE_RATE_WEBVIEW_ERRORS = [
  'webglcontextlost',
  'This Suspense boundary received an update before it finished hydrating',
  'https://reactjs.org/docs/error-decoder.html?invariant=421',
]

/**
 * @param sampleRate 기본 90% 확률로 무시
 */
function getIgnoreSampleRate(sampleRate = 0.9) {
  return Math.random() < sampleRate
}

function isMoreSampleRateError(event: Sentry.Event, errors: string[]) {
  if (event.level !== 'error') {
    return false
  }

  return event.exception?.values?.some((item) => {
    return errors.find((value) => item.value?.startsWith(value))
  })
}

export const initSentry = () => {
  const isAffectByIssue3388 = navigator.userAgent.includes('Chrome/74.0.3729')

  Sentry.init({
    environment: STAGE,
    release: VERSION,
    dsn: SENTRY_DSN,
    integrations: [
      new SentryBrowser.BrowserTracing(),
      new SentryBrowser.Integrations.TryCatch({
        requestAnimationFrame: !isAffectByIssue3388,
      }),
    ],
    beforeSend(event) {
      if (
        isMoreSampleRateError(event, MORE_SAMPLE_RATE_WEBVIEW_ERRORS) &&
        getIgnoreSampleRate(0.9)
      ) {
        return null
      }

      if (
        isMoreSampleRateError(event, MORE_SAMPLE_RATE_NATIVE_ERRORS) &&
        getIgnoreSampleRate(0.9)
      ) {
        return null
      }

      return event
    },
    ignoreErrors: [
      'Java exception was raised during method invocation', // 안드로이드 앱 인스턴스가 내려갔는데, 브릿지 호출 시 발생하는 에러로 무시처리 (유저 영향 X)
      'ResizeObserver loop limit exceeded', // 크롬 일부 버전에서만 발생하는 에러로, 무해하다고 함.
      'Request aborted',
      'timeout of 0ms exceeded',
      'timeout exceeded',
    ],
    tracesSampleRate: 0,
    sampleRate: STAGE === 'production' ? 0.1 : 1.0,
  })
}
