import { SafeAreaInsetTop } from '../_app/components/safe-area-inset/SafeAreaInset'
import SearchInputNavbarSkeleton from '../_app/components/skeleton/SearchInputNavbarSkeleton'
import { IS_ENV_SSR } from '../_app/constants/common'
import { useRenderEnvironment } from '../_app/context/NodeOnlyRenderPlatformProvider'
import { getCookie } from '../_app/stores/PersistentStore'

interface PageHomeSearchSkeletonProps {
  query: string
  onBackClick: () => void
}

const PageHomeSearchSkeleton = (props: PageHomeSearchSkeletonProps) => {
  const renderEnvironment = useRenderEnvironment()
  // iOS 초기 1회 덜그덕거리는 렌더링 수정
  if (
    !IS_ENV_SSR &&
    renderEnvironment.isIOS &&
    (!getCookie('env(safe-area-inset-top)') ||
      !localStorage.getItem('env(safe-area-inset-top)')) //추후 local-storage 참조 제거
  ) {
    return null
  }

  return (
    <>
      <SafeAreaInsetTop />
      <SearchInputNavbarSkeleton
        platform={renderEnvironment.platform}
        inputValue={props.query}
        onBackClick={props.onBackClick}
      />
    </>
  )
}

export default PageHomeSearchSkeleton
