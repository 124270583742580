import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_BFF_PUBLIC_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import type { SearchReferrerType } from '../../referrer/types'
import { RecentSearchApi } from '../../__codegen__/__openapi__/search-bff'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'
import type { WebviewConfigType } from '../../_app/types'
interface RecentSearchReferrerType {
  referrer: Pick<SearchReferrerType, 'experiment'>
}

interface RecentSearchDeleteAllRequest extends RecentSearchReferrerType {}

interface RecentSearchDeleteKeywordRequest extends RecentSearchReferrerType {
  query: string
}

interface RecentSearchDeleteShortcutRequest extends RecentSearchReferrerType {
  shortcutId: string
}

interface RecentSearchGetAllKeywordsRequest extends RecentSearchReferrerType {
  regionId: string
}

export type ServiceRecentSearchType = ReturnType<typeof getServiceRecentSearch>

export const getServiceRecentSearch = ({
  user,
  app,
  webviewConfig,
  __NODE_ONLY_bypassHeaders,
}: {
  user: UserType
  app: AppType
  webviewConfig: WebviewConfigType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: webviewConfig.httpTimeoutMs ?? NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({
            userAgent: app.userAgent,
            os: app.os,
            version: app.version,
          }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: webviewConfig.retries ?? RETRY_COUNT,
    retryDelay: () => webviewConfig.retryDelay ?? 0,
    retryCondition: () => webviewConfig.retryCondition ?? true,
    shouldResetTimeout: true,
  })

  const client = new RecentSearchApi(
    undefined,
    SEARCH_BFF_PUBLIC_ENDPOINT,
    axiosInstance
  )

  return {
    deleteAllRecentQuery: async ({
      referrer,
    }: RecentSearchDeleteAllRequest) => {
      const [error, response] = await to(
        client.apiV1RecentSearchesDelete(
          {},
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('error' in response.data) {
        return {
          result: null,
          error: response.data.details,
        }
      }

      return {
        result: 'success',
      }
    },
    deleteRecentKeyword: async ({
      query,
      referrer,
    }: RecentSearchDeleteKeywordRequest) => {
      const [error, response] = await to(
        client.apiV1RecentSearchesQueryDelete(
          {
            apiV1RecentSearchesQueryDeleteRequest: {
              query,
            },
          },
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('error' in response.data) {
        return {
          result: null,
          error: response.data.details,
        }
      }

      return {
        result: 'success',
      }
    },
    deleteRecentShortcut: async ({
      shortcutId,
      referrer,
    }: RecentSearchDeleteShortcutRequest) => {
      const [error, response] = await to(
        client.apiV1RecentSearchesShortcutDelete(
          {
            apiV1RecentSearchesShortcutDeleteRequest: {
              shortcutId,
            },
          },
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('error' in response.data) {
        return {
          result: null,
          error: response.data.details,
        }
      }

      return {
        result: 'success',
      }
    },
    getRecentSearchQueries: async ({
      regionId,
      referrer,
    }: RecentSearchGetAllKeywordsRequest) => {
      const [error, response] = await to(
        client.apiV1RecentSearchesGet(
          { regionId },
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('error' in response.data) {
        return {
          queries: [],
          error: response.data.details,
        }
      }

      return {
        queries: response.data.queries || [],
      }
    },
  }
}
