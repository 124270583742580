import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'
import { getApp, getRegion, getUser } from '../../bridge/utils/bridgeMaker'

export interface RegionType {
  id: number
  name: string
  centerCoordinates?: {
    latitude: number
    longitude: number
  }
}

export interface UserType {
  id: number
  /**
   * @deprecated
   * 앱 환경에서는 plantaeAuthPlugin 내부에서 access-token, fallback auth-token을 사용하지만,
   * e2e, search-center preview 환경에서 현재 authorization 헤더를 주입하고 있지 않아,
   * auth-token을 명시적으로 사용하기 위해 사용됨
   *
   * 추후 e2e, search-center preview에서 헤더에 x-auth-karrot-user-id 주입하게 되면 로직 제거
   */
  authToken?: string | undefined
  nickname: string
}

export interface AppType {
  /**
   * @deprecated
   *
   * SSR에서는 해당 정보를 조회할 수 없고, 장기적으로 사용하지 않을 예정
   *
   * 공통 헤더
   * https://www.notion.so/daangn/HTTP-9d4e958852dd45ba9bb85ae60721bff5
   */
  userAgent?: string
  os: 'IOS' | 'ANDROID' | 'UNKNOWN'
  osVersion: string
  version: string
  deviceIdentity: string
}

export interface FoundationType {
  app: AppType
  user: UserType
  region: RegionType
}

function extractOperatingSystemFrom(userAgent: string) {
  const [, osInfo] = userAgent.split(' ')
  const [os, version] = (osInfo || 'iOS').split('/')

  const upperCaseOSName = (() => {
    switch (os) {
      case 'iOS':
        return 'IOS'
      case 'Android':
        return 'ANDROID'
      default:
        return 'UNKNOWN'
    }
  })()

  return {
    os: upperCaseOSName,
    version,
  } as const
}

function extractAppVersionFrom(userAgent: string) {
  const [appInfo] = userAgent.split(' ')
  const [, version] = appInfo.split('/')

  return version
}

function makeFoundationResource() {
  type State =
    | {
        _t: 'pending'
      }
    | {
        _t: 'rejected'
        result: Error
      }
    | {
        _t: 'resolved'
        result: FoundationType
      }

  let state: State = {
    _t: 'pending',
  }

  const promise = (async () => {
    try {
      const [app, user, region] = await Promise.all([
        getApp(),
        getUser(),
        getRegion(),
      ])

      if (!app) {
        return void (state = {
          _t: 'rejected',
          result: new Error(
            'Failed to get information to karrotBridge.getAppInfo({})'
          ),
        })
      }

      if (!user || !user.id || !user.nickname || !user.authToken) {
        return void (state = {
          _t: 'rejected',
          result: new Error(
            'Failed to get information to karrotBridge.getUserInfo({})'
          ),
        })
      }

      if (!region || !region.id || !region.name) {
        return void (state = {
          _t: 'rejected',
          result: new Error(
            'Failed to get information to karrotBridge.getRegionInfo({})'
          ),
        })
      }

      const { os, version: osVersion } = extractOperatingSystemFrom(
        app.userAgent
      )

      state = {
        _t: 'resolved',
        result: {
          app: {
            userAgent: app.userAgent,
            os: os,
            osVersion: osVersion,
            version: extractAppVersionFrom(app.userAgent),
            deviceIdentity: app.deviceIdentity,
          },
          user: {
            id: user.id,
            nickname: user.nickname,
            authToken: user.authToken,
          },
          region: {
            id: region.id,
            name: region.name,
            centerCoordinates: region.centerCoordinates,
          },
        },
      }
    } catch (error: any) {
      state = {
        _t: 'rejected',
        result: error,
      }
    }
  })()

  return {
    read() {
      /* eslint-disable default-case */
      switch (state._t) {
        case 'pending':
          throw promise
        case 'rejected':
          throw state.result
        case 'resolved':
          return state.result
      }
    },
  }
}

const foundationResource = makeFoundationResource()

const FoundationResourceContext = createContext<FoundationType>(null as any)

interface FoundationProviderProps {
  foundation?: FoundationType
  children: ReactNode
}

export const FoundationProvider = (props: FoundationProviderProps) => {
  return (
    <FoundationResourceProvider foundation={props.foundation}>
      {props.children}
    </FoundationResourceProvider>
  )
}

interface FoundationResourceProviderProps {
  foundation?: FoundationType
  children: ReactNode
}

const FoundationResourceProvider = (props: FoundationResourceProviderProps) => {
  const foundation = props.foundation ?? foundationResource.read()

  const memoriesFoundation = useMemo(
    () => ({
      app: foundation.app,
      user: foundation.user,
      region: foundation.region,
    }),
    [foundation.app, foundation.user, foundation.region]
  )

  return (
    <FoundationResourceContext.Provider value={memoriesFoundation}>
      {props.children}
    </FoundationResourceContext.Provider>
  )
}

export function useFoundation() {
  return useContext(FoundationResourceContext)
}
