import to from 'await-to-js'
import axios from 'axios'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  SEARCH_BFF_PUBLIC_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeKarrotUserIdPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'
import { FleamarketApi } from '../../__codegen__/__openapi__/search-bff/api/fleamarket-api'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'
import type { WebviewConfigType } from '../../_app/types'

export type ServiceKeywordNotificationType = ReturnType<
  typeof getServiceKeywordNotification
>

export const getServiceKeywordNotification = ({
  app,
  user,
  webviewConfig,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  webviewConfig: WebviewConfigType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: webviewConfig.httpTimeoutMs ?? NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({
            userAgent: app.userAgent,
            os: app.os,
            version: app.version,
          }),
          plantaeKarrotUserIdPlugin({ app, userId: user.id }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  const client = new FleamarketApi(
    undefined,
    SEARCH_BFF_PUBLIC_ENDPOINT,
    axiosInstance
  )

  return {
    async createUserKeywordNotification({ keyword }: { keyword: string }) {
      const [error, resp] = await to(
        client.apiV1FleamarketKeywordSearchUserKeywordsPost({
          where: 'search_result',
          screenName: 'search',
          apiV1FleamarketKeywordSearchUserKeywordsPostRequest: {
            keyword: keyword,
          },
        })
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      return resp.data
    },
    async getKeywordNotificationInfo({
      keyword,
      referrer,
    }: {
      keyword: string
      referrer: {
        experiment: SearchExperimentReferrerType
      }
    }) {
      const [error, resp] = await to(
        client.apiV1FleamarketKeywordNotificationInfoGet(
          {
            keyword,
          },
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      return resp.data
    },
  }
}
